const baseUrl = '/clinics/combine-clinic-2021'

const combineSetupUrl = `${baseUrl}/combine-setup`
const combineCalibrationsUrl = `${baseUrl}/combine-calibrations`
const amsBasicsUrl = `${baseUrl}/ams-basics`
const operationsCenterUrl = `${baseUrl}/operations-center`
const wheatDrillOptimizationUrl = `${baseUrl}/wheat-drill-optimization`

const combineClinic2021Data = [
  {
    key: 'combineSetup',
    title: 'Combine Set-up and Optimization',
    subtitle: 'Get the most out of your combine',
    description:
      "This set of educational videos will walk you through setting up your combine for optimal performance in both corn and soybeans. We will also show you some useful tools on your smartphone that can help you optimize your combine's performance.",
    videos: [
      {
        title: 'GoHarvest App - Setting Up Your Combine',
        link: `${combineSetupUrl}?videoId=mbIibJBm6FA`,
      },
      {
        title: 'Equipment Plus App - Setting Up Your Combine',
        link: `${combineSetupUrl}?videoId=OGNE1bYiAcs`,
      },
      { title: 'Setting Your Combine Up for Corn', link: `${combineSetupUrl}?videoId=-J5vmEpVp4g` },
      {
        title: 'Setting Your Combine Up for Soybeans',
        link: `${combineSetupUrl}?videoId=jhX8lr_nnMc`,
      },
      {
        title: 'GoHarvest App - Optimizing Performance',
        link: `${combineSetupUrl}?videoId=f1WTDHyomt0`,
      },
      {
        title: 'Track Spacing - Working Width Change Notification',
        link: `${combineSetupUrl}?videoId=IQIAEADVOV8`,
      },
      {
        title: 'How to Set Programmable Buttons - S700 John Deere Combine',
        link: `${combineSetupUrl}?videoId=rN5m6rULIQk`,
      },
      // { title: 'Uneven double crop bean stand due to uneven wheat residue spread', link: `${combineSetupUrl}?videoId=` },
      {
        title: 'Importance of Combine Spread Pattern for Stands',
        link: `${combineSetupUrl}?videoId=6bJds_6HbTc`,
      },
      {
        title: 'Importance of Combine Spread Pattern for Soil Fertility',
        link: `${combineSetupUrl}?videoId=ujqAINiamhU`,
      },
      { title: 'Spread Pattern Optimization', link: `${combineSetupUrl}?videoId=Hak0mn089-k` },
      { title: 'Setting Auger Bed Dividers', link: `${combineSetupUrl}?videoId=6aScFmM0TQU` },
    ],
    url: combineSetupUrl,
    imageKey: 'combineSetupImage',
    playlist: 'PLUz5fj7f_mw9zQkQhNPYFzQ8KtX59WJXa',
  },
  {
    key: 'combineCalibrations',
    title: 'Combine Calibrations',
    subtitle: 'Common calibrations you need to do',
    description:
      'In this session, we will walk you through some common calibrations that need to be completed on your combine.',
    videos: [
      {
        title: 'Active Terrain - Chassis Pitch Calibration',
        link: `${combineCalibrationsUrl}?videoId=nhfZsdpubOU`,
      },
      {
        title: 'Lateral Tilt Speed Calibration',
        link: `${combineCalibrationsUrl}?videoId=2Yf2rSaaTjw`,
      },
      {
        title: 'Feeder House Raise Lower Speed Calibration',
        link: `${combineCalibrationsUrl}?videoId=OQaMt_xy8PI`,
      },
      { title: 'Header Calibration', link: `${combineCalibrationsUrl}?videoId=_3RJ2uKKe98` },
      { title: 'Reel Position Calibration', link: `${combineCalibrationsUrl}?videoId=qFOy55H0x-g` },
      {
        title: 'Fixing Moisture Correction',
        link: `${combineCalibrationsUrl}?videoId=mjGxrOHssXE`,
      },
      {
        title: 'Active Yield Correction % - Gen 4',
        link: `${combineCalibrationsUrl}?videoId=lK8rPP0ONWI`,
      },
    ],
    url: combineCalibrationsUrl,
    imageKey: 'combineImage',
    playlist: 'PLUz5fj7f_mw8ZKRy0a9ixMJRTeeb0ac7S',
  },
  {
    key: 'amsBasics',
    title: 'AMS Basics',
    subtitle: 'Common settings in your display',
    description:
      "Get a refresher on common settings in your display. These tips will help get your harvest off to a smooth start and optimize your combine's technology.",
    videos: [
      { title: 'Layout Manager - Gen 2 and Gen 3', link: `${amsBasicsUrl}?videoId=VZCGhi_Ea0g` },
      { title: 'TCM Calibration - 2630', link: `${amsBasicsUrl}?videoId=wFqm9qJecO8` },
      { title: 'Clearing Work Totals - Gen 4', link: `${amsBasicsUrl}?videoId=sgMwQ5xfjQA` },
      { title: 'Layout Manager - Gen 4', link: `${amsBasicsUrl}?videoId=3-0-1Ss42yE` },
      { title: 'TCM Calibration - Gen 4', link: `${amsBasicsUrl}?videoId=eBI1nlav20c` },
      { title: 'New JDLink Subscription Process', link: `${amsBasicsUrl}?videoId=AqROjOu1Q4o` },
    ],
    url: amsBasicsUrl,
    imageKey: 'displayImage',
    playlist: 'PLUz5fj7f_mw8go22c2cz3zxRpUEikHw9E',
  },
  {
    key: 'operationsCenter',
    title: 'Operations Center',
    subtitle: 'Getting Started in MyJohnDeere Operations Center',
    description:
      'This set of videos will walk you through the process of setting up your MyJohnDeere account, along with introductions into basic functionality of Operations Center. After you watch this session, you will be ready to start using your data to help make future decisions on your farm.',
    videos: [
      { title: 'My Operations App - Analyze', link: `${operationsCenterUrl}?videoId=CPgXnXMt5ec` },
      { title: 'My Operations App - Map', link: `${operationsCenterUrl}?videoId=NLUrXITLf6k` },
      { title: 'My Operations App - Home', link: `${operationsCenterUrl}?videoId=biDZySoJIXo` },
      {
        title: 'New JDLink Subscription Process ',
        link: `${operationsCenterUrl}?videoId=AqROjOu1Q4o`,
      },
      {
        title: 'How to Create a New MyJohnDeere Account',
        link: `${operationsCenterUrl}?videoId=lDMf_qs3lCo`,
      },
      {
        title: 'Adding Boundaries into Ops Center',
        link: `${operationsCenterUrl}?videoId=v9FMtzhh-bc`,
      },
      { title: 'Loading Data into Ops Center', link: `${operationsCenterUrl}?videoId=b47lm2IYIjk` },
      {
        title: 'Files and MyJohnDeere Data Manager',
        link: `${operationsCenterUrl}?videoId=WI8CuAiPhAM`,
      },
      { title: 'Operations Center New Layout', link: `${operationsCenterUrl}?videoId=DMsnPzyT7wo` },
      {
        title: 'How to Access Near Real Time Updates',
        link: `${operationsCenterUrl}?videoId=N3IhwPpZins`,
      },
    ],
    url: operationsCenterUrl,
    imageKey: 'operationsCenterImage',
    playlist: 'PLUz5fj7f_mw8Lfh3AOwIhG-tegbJtUKl3',
  },
  {
    key: 'wheatDrillOptimization',
    title: 'Wheat Drill Optimization',
    subtitle: 'Settings, adjustments and more information',
    description:
      'This session gives a brief overview of the new N500C Air Drill. Also, learn how to adjust and set down pressure and seeding depth when planting your wheat crop this Fall.',
    videos: [
      {
        title: 'N500 Section Command & Section Control',
        link: `${wheatDrillOptimizationUrl}?videoId=WLOlWbE4Rcs`,
      },
      {
        title: 'N500 Drill Pro Series Opener - Overview',
        link: `${wheatDrillOptimizationUrl}?videoId=3s5Fchczh0s`,
      },
      {
        title: 'Importance of Wheat Seeding Depth',
        link: `${wheatDrillOptimizationUrl}?videoId=yan_iwR7WBA`,
      },
      {
        title: 'N500 Pro Series Opener Proper Wheat Seeding Depth',
        link: `${wheatDrillOptimizationUrl}?videoId=M9m7BGaep2c`,
      },
      {
        title: 'Wheat Drill Down Pressure',
        link: `${wheatDrillOptimizationUrl}?videoId=gUGZ8s4QCCA`,
      },
    ],
    url: wheatDrillOptimizationUrl,
    imageKey: 'n500cImage',
    playlist: 'PLUz5fj7f_mw-1xQ5KibMLQYYmD9VYrHRZ',
  },
]

export default combineClinic2021Data
